import React, { ReactNode } from "react"
import { Container, Stack, useTheme } from "@mui/material"

type PageWrapperProps = {
	children: ReactNode
}

export default function PageWrapper( props: PageWrapperProps ) {
	const theme = useTheme()
	return (
		<Container
			sx={ {
				backgroundColor: theme.palette.primary.main,
				height: "100%",
			} }
			maxWidth={ false }
		>
			<Container
				sx={ {
					height: "100%",
				} }
				maxWidth={ "xs" }
			>
				<Stack
					alignItems={ "center" }
					justifyContent={ "center" }
					direction={ "row" }
					sx={ {
						height: "100%",
					} }
				>
					{ props.children }
				</Stack>
			</Container>
		</Container>
	)
}
