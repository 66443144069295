export class ColorUtil {

	static hsvToRgb( hue: number, saturation: number, value: number ): string {
		let rgb = [ 0, 0, 0 ];
		for( let i = 0; i < 4; i++ ) {
			if( Math.abs( hue - i * 120 ) < 120 ) {
				const distance = Math.max( 60, Math.abs( hue - i * 120 ) );
				rgb[ i % 3 ] = 1 - ((distance - 60) / 60);
			}
		}
		//desaturate by increasing lower levels
		const max = Math.max( ...rgb );
		const factor = 255 * (value / 100);

		for( let i = 0; i < 3; i++ ) {
			//use distance between 0 and max (1) and multiply with value
			rgb[ i ] = Math.round( (rgb[ i ] + (max - rgb[ i ]) * (1 - saturation / 100)) * factor );
		}

		return "#" + this.decToSixHex( rgb[ 0 ] ) + this.decToSixHex( rgb[ 1 ] ) + this.decToSixHex( rgb[ 2 ] );
	}

	/**
	 * @param decimal
	 */
	static decToSixHex( decimal: number ): string {
		return decimal.toString( 16 ).padStart( 2, "0" );
	}

	static rainbowRgb( numSteps: number, saturation: number, value: number ): string[] {
		const colors = [];
		for( let i = 0; i < numSteps; i++ ) {
			colors.push( this.hsvToRgb( 360 / numSteps * i, saturation, value ) );
		}

		return colors;
	}
}