import React, { Fragment, RefAttributes, useEffect, useRef, useState } from "react"
import { MapContainer, Marker, MarkerProps, Polyline, Popup, TileLayer } from "react-leaflet"
import { Marker as LeafletMarker } from "leaflet"
import "leaflet/dist/leaflet.css"
import {
	Box,
	Button, Checkbox,
	Drawer,
	IconButton, List, ListItem, ListItemButton, ListItemText,
	Stack,
	ToggleButton,
	ToggleButtonGroup,
	ToggleButtonProps,
	Typography,
	useTheme,
} from "@mui/material"
import { Feature, LineString } from "geojson"
import L, { DivIcon, LatLng } from "leaflet"
import { ColorUtil } from "src/ColorUtil"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay"
import { faBackward } from "@fortawesome/free-solid-svg-icons/faBackward"
import { faFastBackward } from "@fortawesome/free-solid-svg-icons/faFastBackward"
import { faForward } from "@fortawesome/free-solid-svg-icons/faForward"
import { faPause } from "@fortawesome/free-solid-svg-icons/faPause"
import { faFastForward } from "@fortawesome/free-solid-svg-icons/faFastForward"
import { faStepBackward } from "@fortawesome/free-solid-svg-icons/faStepBackward"
import { faStepForward } from "@fortawesome/free-solid-svg-icons/faStepForward"
import { faChartArea } from "@fortawesome/free-solid-svg-icons/faChartArea"
import StatsDialogComponent from "./../components/StatsDialogComponent"
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons/faEyeSlash"
import { faLowVision } from "@fortawesome/free-solid-svg-icons/faLowVision"
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt"
import { faComment } from "@fortawesome/free-solid-svg-icons/faComment"
import { faBicycle } from "@fortawesome/free-solid-svg-icons/faBicycle"
import { renderToStaticMarkup } from "react-dom/server"
import JsonCollection from "src/types/JsonCollection"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft"
import { Link } from "react-router-dom"

const defaultPositions: Record<"cz"| "bk", [number, number]> = {
	cz: [ 49.7438, 15.3386 ],
	bk: [ 48.2101375, 17.1936325 ],
}

const getIcon = ( feature: Feature<LineString, { timestamp: number[], name: string, finished: boolean }> ) => {
	return new L.DivIcon( {
		iconSize: [ 16, 16 ],
		html: "<span style='display: block; width: max-content; font-size: 0.9em; background: #fff; border: 1px solid #333'>" + feature.properties.name + "</span>",
	} )
}

const getMarker = () => {
	return new DivIcon( {
		html: renderToStaticMarkup( <MapMarker/> ),
		iconSize: [ 30, 24 ],
		iconAnchor: [ 15, 12 ],
		className: "",
	} )
}

function StyledToggleButton( props: ToggleButtonProps ) {
	return (
		<ToggleButton
			{ ...props }
			sx={ {
				...props.sx,
				justifyContent: "flex-start",
				gap: 1,
			} }
		/>
	)
}

const speedVariants = [
	{
		name: "1m",
		value: 60,
	},
	{
		name: "5m",
		value: 60 * 5,
	},
	{
		name: "10m",
		value: 60 * 10,
	},
	{
		name: "30m",
		value: 60 * 30,
	},
	{
		name: "1h",
		value: 60 * 60,
	},
]

const playRatio = 1000

type MapViewProps = {
	geoJson: JsonCollection,
}

function MapView( { geoJson }: MapViewProps ) {
	const [ colors, setColors ] = useState<string[]>()
	const [ ridersTimestampKeys, setRidersTimestampKeys ] = useState<( number | undefined )[]>()
	const [ isPlaying, setIsPlaying ] = useState( false )
	const [ speed, setSpeed ] = useState( 60 )
	const [ time, setTime ] = useState( geoJson.startAt )
	const [ namesVariant, setNamesVariant ] = useState<"hidden" | "icon" | "detail">( "detail" )
	const [ routesVariant, setRoutesVariant ] = useState<"hidden" | "driven" | "full">( "driven" )
	const [ openedStats, setOpenedStats ] = useState( false )
	const [ selectedRiders, setSelectedRiders ] = useState<boolean[]>( geoJson.data.features.map( () => false ) )
	const [ showAllRiders, setShowAllRiders ] = useState<boolean>( true )
	const [ clickedRider, setClickedRider ] = useState<number>()
	const timeRef = useRef( time )
	const isPlayingRef = useRef( isPlaying )
	const speedRef = useRef( speed )
	timeRef.current = time
	isPlayingRef.current = isPlaying
	speedRef.current = speed
	const defaultPosition = defaultPositions[ geoJson.title === "RTBK Alleycat 2024" ?"bk":"cz" ]

	useEffect( () => {
		setColors( ColorUtil.rainbowRgb( geoJson.data.features.length, 100, 100 ) )
	}, [ geoJson ] )

	const handlePlay = () => {
		if ( isPlayingRef.current ) {
			setTime( timeRef.current + speedRef.current )
			setTimeout( handlePlay, playRatio )
		}
	}

	useEffect( () => {
		handlePlay()
	}, [ isPlaying ] )

	useEffect( () => {
		setIsPlaying( false )
	}, [ openedStats ] )

	useEffect( () => {
		const ridersTimestampKeys = geoJson.data.features.map( ( feature, featureKey ) => {
			const indexTimestamp = feature.properties.timestamp.findIndex( timestamp => timestamp > time ) - 1
			return indexTimestamp === -2 ? undefined : ( indexTimestamp >= 0 ? indexTimestamp : 0 )
		} )
		setRidersTimestampKeys( ridersTimestampKeys )
	}, [ time, geoJson ] )

	useEffect( () => {
		if ( selectedRiders.every( active => active ) || selectedRiders.every( active => !active ) ) {
			setShowAllRiders( true )
		} else {
			setShowAllRiders( false )
		}
	}, [ selectedRiders ] )

	return (
		<>
			<Drawer
				anchor={ "right" }
				open
				variant={ "permanent" }
			>
				<Button
					startIcon={ <FontAwesomeIcon icon={ faChevronLeft }/> }
					component={ Link }
					to={ "/" }
				>
					Zpět na výběr závodu
				</Button>
				<hr/>
				<Box
					sx={ {
						textAlign: "center",
					} }
				>
					{ geoJson.title }
				</Box>
				<Typography
					variant={ "h6" }
					sx={ {
						textAlign: "center",
						mt: 1,
					} }
				>
					{ new Date( time * 1000 ).toLocaleDateString() }
					{ " " }
					{ new Date( time * 1000 ).toLocaleTimeString() }
				</Typography>
				<Stack
					direction={ "row" }
					justifyContent={ "center" }
					alignItems={ "center" }
				>
					<IconButton
						onClick={ () => setTime( geoJson.startAt ) }
						size={ "small" }
					>
						<FontAwesomeIcon icon={ faFastBackward }/>
					</IconButton>
					<IconButton
						onClick={ () => setTime( time - 60 * 10 ) }
						size={ "small" }
					>
						<FontAwesomeIcon icon={ faBackward }/>
					</IconButton>
					<IconButton
						onClick={ () => setTime( time - 60 ) }
						size={ "small" }
					>
						<FontAwesomeIcon icon={ faStepBackward }/>
					</IconButton>
					<IconButton
						onClick={ () => setIsPlaying( !isPlaying ) }
						color={ "primary" }
						size={ "medium" }
					>
						<FontAwesomeIcon icon={ isPlaying ? faPause : faPlay }/>
					</IconButton>
					<IconButton
						onClick={ () => setTime( time + 60 ) }
						size={ "small" }
					>
						<FontAwesomeIcon icon={ faStepForward }/>
					</IconButton>
					<IconButton
						onClick={ () => setTime( time + 60 * 10 ) }
						size={ "small" }
					>
						<FontAwesomeIcon icon={ faForward }/>
					</IconButton>
					<IconButton
						size={ "small" }
						onClick={ () => setTime( time + 60 * 60 * 24 ) }
					>
						<FontAwesomeIcon icon={ faFastForward }/>
					</IconButton>
				</Stack>
				<Button
					onClick={ () => setOpenedStats( true ) }
					startIcon={ <FontAwesomeIcon icon={ faChartArea }/> }
					variant={ "outlined" }
					sx={ {
						mx: 1,
					} }
				>
					Statistiky
				</Button>
				<Stack
					sx={ {
						m: 1,
					} }
				>
					<Typography>Rychlost</Typography>
					<ToggleButtonGroup
						value={ speed }
						exclusive
						fullWidth
						onChange={ ( event, value ) => setSpeed( value ) }
						aria-label="text alignment"
					>
						{ speedVariants.map( item => (
							<StyledToggleButton value={ item.value } aria-label="left aligned">
								{ item.name }
							</StyledToggleButton>
						) ) }
					</ToggleButtonGroup>
				</Stack>

				<Stack
					sx={ {
						m: 1,
					} }
				>
					<Typography>Ikony</Typography>
					<ToggleButtonGroup
						value={ namesVariant }
						exclusive
						onChange={ ( event, value ) => setNamesVariant( value ) }
						aria-label="text alignment"
						orientation={ "vertical" }
					>
						<StyledToggleButton value={ "hidden" } aria-label="left aligned">
							<FontAwesomeIcon icon={ faEyeSlash } fixedWidth/>
							Skrýt
						</StyledToggleButton>
						<StyledToggleButton value={ "icon" } aria-label="centered">
							<FontAwesomeIcon icon={ faMapMarkerAlt } fixedWidth/>
							Zobrazit jako ikonu
						</StyledToggleButton>
						<StyledToggleButton value={ "detail" } aria-label="right aligned">
							<FontAwesomeIcon icon={ faComment } fixedWidth/>
							Zobrazit jako detail
						</StyledToggleButton>
					</ToggleButtonGroup>
				</Stack>

				<Stack
					sx={ {
						m: 1,
					} }
				>
					<Typography>Trasy</Typography>
					<ToggleButtonGroup
						value={ routesVariant }
						exclusive
						onChange={ ( event, value ) => setRoutesVariant( value ) }
						aria-label="text alignment"
						orientation={ "vertical" }
					>
						<StyledToggleButton value={ "hidden" } aria-label="left aligned">
							<FontAwesomeIcon icon={ faEyeSlash } fixedWidth/>
							Skrýt trasy
						</StyledToggleButton>
						<StyledToggleButton value={ "driven" } aria-label="centered">
							<FontAwesomeIcon icon={ faLowVision } fixedWidth/>
							Pouze projeté
						</StyledToggleButton>
						<StyledToggleButton value={ "full" } aria-label="right aligned">
							<FontAwesomeIcon icon={ faEye } fixedWidth/>
							Vše
						</StyledToggleButton>
					</ToggleButtonGroup>
				</Stack>

				<List dense>
					{ geoJson.data.features.map( ( feature, index ) => (
						<ListItem
							secondaryAction={
								<Checkbox
									edge={ "end" }
									checked={ selectedRiders[ index ] }
									onChange={ ( event, checked ) => {
										const newRiders = [ ...selectedRiders ]
										newRiders[ index ] = checked
										setSelectedRiders( newRiders )
									} }
								/>
							}
							disablePadding
						>
							<ListItemButton
								onClick={ () => setClickedRider( index ) }
								selected={ index === clickedRider }
								disabled={ ridersTimestampKeys && ridersTimestampKeys[ index ] === undefined }
							>
								<ListItemText primary={ feature.properties.name }/>
							</ListItemButton>
						</ListItem>
					) ) }
				</List>

			</Drawer>
			<MapContainer
				style={ { width: "100vw", height: "100vh" } }
				center={ [ defaultPosition[ 0 ], defaultPosition[ 1 ] ] }
				zoom={ 8 }
			>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				{ ridersTimestampKeys && geoJson.data.features.map( ( feature, key ) => {
					if ( !showAllRiders && !selectedRiders[ key ] && clickedRider !== key ) {
						return null
					}
					return (
						<Fragment key={ key }>
							{ namesVariant !== "hidden" && ridersTimestampKeys[ key ] !== undefined &&
							(
								clickedRider === key ?
									(
										<MyMarker
											position={ [
												feature.geometry.coordinates[ ridersTimestampKeys[ key ] || 0 ][ 1 ],
												feature.geometry.coordinates[ ridersTimestampKeys[ key ] || 0 ][ 0 ],
											] }
											icon={ namesVariant === "detail" ? getIcon( feature ) : getMarker() }
											eventHandlers={ {
												popupclose: () => setClickedRider( undefined ),
											} }
										>
											<Popup>
												{ feature.properties.name }
											</Popup>
										</MyMarker>
									) : (
										<Marker
											position={ [
												feature.geometry.coordinates[ ridersTimestampKeys[ key ] || 0 ][ 1 ],
												feature.geometry.coordinates[ ridersTimestampKeys[ key ] || 0 ][ 0 ],
											] }
											eventHandlers={ {
												click: () => setClickedRider( key ),
											} }
											icon={ namesVariant === "detail" ? getIcon( feature ) : getMarker() }>
										</Marker>
									)
							)
							}
							{ routesVariant !== "hidden" &&
                            <Polyline
                                positions={
									routesVariant === "full" || ridersTimestampKeys[ key ] === undefined
										?
										feature.geometry.coordinates.map( coord => new LatLng( coord[ 1 ], coord[ 0 ] ) )
										:
										feature.geometry.coordinates.slice( 0, ( ridersTimestampKeys[ key ] || 0 ) + 1 ).map( coord => new LatLng( coord[ 1 ], coord[ 0 ] ) ) }
                                color={ colors && colors[ key ] }
								//opacity={selectedRider === key ? 0.5 : 0.5}
                            />
							}
						</Fragment>
					)
				} )
				}
			</MapContainer>
			<StatsDialogComponent
				geoJson={ geoJson.data }
				open={ openedStats }
				handleClose={ () => setOpenedStats( false ) }
				fullScreen
			/>
		</>
	)
}

function MapMarker() {
	const theme = useTheme()
	return (
		<Box sx={ {
			color: theme.palette.primary.main,
			filter: `drop-shadow(1px 1px 1px #00000055)`,
		} }>
			<FontAwesomeIcon icon={ faBicycle } size={ "2x" }/>
		</Box>
	)
}

function MyMarker( props: MarkerProps & RefAttributes<LeafletMarker<undefined>> ) {
	const leafletRef = useRef<LeafletMarker<undefined>>()
	useEffect( () => {
		if ( leafletRef.current ) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			leafletRef.current[ "_map" ].flyTo( props.position )
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			leafletRef.current.openPopup()
		}
	}, [ props.position ] )
	//@ts-ignore
	return <Marker ref={ leafletRef } { ...props } />
}


export default MapView
