import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import PageWrapper from "src/components/PageWrapper"
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner"
import { Stack, Typography } from "@mui/material"
import axios from "axios"
import JsonCollection from "src/types/JsonCollection"
import MapView from "src/views/MapView"

type MapViewLoaderProps = {}

export default function MapViewLoader( props: MapViewLoaderProps ) {
	const { ride } = useParams()
	const [ json, setJson ] = useState<JsonCollection>()

	useEffect( () => {
		axios.get<JsonCollection>( `/data/${ ride }.json` ).then( ( { data } ) => {
			setJson( data )
		} )
	}, [] )

	if ( json !== undefined ) {
		return (
			<MapView geoJson={ json }/>
		)
	}

	return (
		<PageWrapper>
			<Typography color={ "primary.light" }>
				<Stack
					alignItems={ "center" }
				>
					<FontAwesomeIcon icon={ faSpinner } spin size={ "5x" }/>
					Načítám { ride }
				</Stack>
			</Typography>
		</PageWrapper>
	)
}
