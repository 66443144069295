import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { isProduction } from "./functions/isProduction"
import TagManager from "react-gtm-module"
import moment from "moment"
import "moment/locale/cs"
import App from "src/App"

if ( isProduction() ) {
    const tagManagerArgs = {
        gtmId: "GTM-M7NMJPK",
    }
    TagManager.initialize( tagManagerArgs )
}
moment.locale("cs")

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
