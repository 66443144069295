import React from "react"
import { Button, Card, CardContent, Stack, Typography, useTheme } from "@mui/material"
import PageWrapper from "src/components/PageWrapper"
import { Link } from "react-router-dom"

const races = [
	"OkoloRepubliky2020",
	"OkoloRepubliky2021",
	"OkoloRepubliky2023",
	"RTBK_Alleycat2024",
]

export default function RidesSelectView() {
	const theme = useTheme()
	return (
		<PageWrapper>
			<Card
				sx={ {
					width: "100%",
				} }
			>
				<CardContent>
					<Typography
						variant={ "h5" }
						component={ "div" }
					>
						Záznam tras
					</Typography>
					<Typography
						variant={ "body2" }>
						Zvolte závod
					</Typography>
					<Stack
						spacing={ 1 }
					>
						{ races.map( race => (
							<Button
								key={ race }
								component={ Link }
								to={ `/${ race }` }
							>
								{ race }
							</Button>
						) ) }
					</Stack>
				</CardContent>
			</Card>
		</PageWrapper>
	)
}
