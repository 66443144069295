import React from "react"
import {createTheme, CssBaseline, GlobalStyles, ThemeProvider} from "@mui/material"
import RidesSelectView from "src/views/RidesSelectView"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import MapViewLoader from "src/views/MapViewLoader"

export default function App() {
	return (
		<ThemeProvider theme={ createTheme( {
			components: {
				MuiToggleButton: {
					styleOverrides: {
						root: {
							textTransform: "none",
						},
					},
				},
			},
		} ) }>
			<CssBaseline/>
			<GlobalStyles styles={{
				".leaflet-div-icon": {
					backgroundColor: "transparent",
					border: "none",
				}
			}} />
			<BrowserRouter>
				<Routes>
					<Route path={ "/" } element={ <RidesSelectView/> }/>
					<Route path={ "/:ride" } element={ <MapViewLoader/> }/>
				</Routes>
			</BrowserRouter>
		</ThemeProvider>
	)
}
