import React, { useState } from "react"
import { AppBar, Button, Dialog, DialogProps, IconButton, Toolbar, Typography } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes"
import { faChartArea } from "@fortawesome/free-solid-svg-icons/faChartArea"
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { getPathLength } from "geolib"
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts"
import moment from "moment"
import { JsonDataCollection } from "src/types/JsonCollection"

function Graph( props: GridRenderCellParams ) {
	const [ showGraph, setShowGraph ] = useState( true )
	const value = props.value as { distance: number, elevation: number }[]

	if ( !showGraph ) {
		return (
			<Button
				variant={ "outlined" }
				onClick={ () => setShowGraph( true ) }
			>
				Zobrazit graf
			</Button>
		)
	} else {
		return (
			<ResponsiveContainer
				width={ "100%" }
				height={ "100%" }
			>
				<LineChart
					data={ value }
				>
					<Line
						type={ "monotone" }
						dataKey={ "elevation" }
						stroke={ "#8884d8" }
						//fill={"#ff0000"}
						dot={ false }
					/>
					<XAxis
						hide
						dataKey={ "distance" }
					/>
					<YAxis
						hide
					/>
				</LineChart>
			</ResponsiveContainer>
		)
	}
}

const columns: GridColDef[] = [
	{
		field: "name",
		headerName: "Jméno",
		flex: 1,
	},
	{
		field: "distance",
		headerName: "Vzdálenost",
		valueFormatter: params => ( params.value as number ) / 1000 + " km",
		flex: 1,
	},
	{
		field: "elevationGraph",
		headerName: "Profil",
		flex: 1,
		renderCell: params => <Graph { ...params } />,
	},
	{
		field: "rideTime",
		headerName: "Čas jízdy",
		flex: 1,
	},
	{
		field: "totalTime",
		headerName: "Celkový čas",
		valueFormatter: params => {
			const duration = moment.duration( params.value as number, "seconds" )
			return `${ moment.duration( duration.days(), "days" ).humanize() } ${ duration.hours() }:${ duration.minutes().toString().padStart( 2, "0" ) }`
		},
		flex: 1,
	},
	{
		field: "finished",
		headerName: "Dojel do cíle",
		flex: 1,
	},
]

type StatsDialogComponentProps = {
	geoJson: JsonDataCollection
	handleClose: () => void
} & DialogProps

export default function StatsDialogComponent( { geoJson, handleClose, open, ...props }: StatsDialogComponentProps ) {

	if ( !open ) {
		return null
	}

	return (
		<Dialog
			{ ...props }
			open={ open }
		>
			<AppBar sx={ { position: "relative" } }>
				<Toolbar>
					<Typography sx={ { ml: 2, flex: 1 } } variant="h6" component="div">
						<FontAwesomeIcon icon={ faChartArea }/> Statistiky
					</Typography>
					<IconButton
						edge="start"
						color="inherit"
						onClick={ handleClose }
						aria-label="close"
					>
						<FontAwesomeIcon icon={ faTimes }/>
					</IconButton>

				</Toolbar>
			</AppBar>
			<DataGrid
				columns={ columns }
				rows={ geoJson.features.map( ( feature, id ) => {
					return {
						id: id,
						name: feature.properties.name,
						distance: getPathLength( feature.geometry.coordinates.map( coord => {
							return { lat: coord[ 1 ], lon: coord[ 0 ] }
						} ) ),
						rideTime: "???",
						totalTime: feature.properties.timestamp[ feature.properties.timestamp.length - 1 ] - feature.properties.timestamp[ 0 ],
						elevationGraph: feature.geometry.coordinates.map( ( coord, key ) => {
							return { distance: feature.properties.timestamp[ key ], elevation: coord[ 2 ] }
						} ),
						finished: feature.properties.finished,
					}
				} ) }

			/>
		</Dialog>
	)
}
